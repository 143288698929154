// Automatically polyfill based on browserlists configuration in babel.config
// Necessary for @babel/preset-env to work
// TODO work out why it’s necessary
import "core-js"
import "regenerator-runtime/runtime"

import "whatwg-fetch"

import "focus-visible"

// Import base JS/CSS definitions
import "./index.css"

// Dependencies
import defo from "@icelab/defo"
import views from "./views"

// Call defo, which handles the binding and execution of DOM initiated JS
// https://www.npmjs.com/package/@icelab/defo
const prefix = "ca"
defo({ prefix, views })

// This will inspect all subdirectories from the context (this file) and
// require files matching the regex.
// https://webpack.js.org/guides/dependency-management/#require-context
require.context(
  ".",
  true,
  /^\.\/.*\.(jpe?g|png|gif|svg|woff2?|ttf|otf|eot|ico|webm|mp4|m4v|pdf)$/,
)
