/**
 * Higher-order function for validating props from a view component
 * @param {Function} component
 * @param {YupSchema} schema
 * @returns {Function}
 */
export function withPropsValidation(component, schema) {
  return async (el, props) => {
    try {
      await schema.validate(props, { strict: true, abortEarly: false })
    } catch (exception) {
      if (exception.name === "ValidationError") {
        return null
      } else {
        throw exception
      }
    }
    return component(el, props)
  }
}
