/**
 * Update the JSON-encoded data attribute of an element
 *
 * @param {DOMNode} el
 * @param {String} datasetKey Element dataset attribute (camelCased)
 * @param {Object} partialValue Partial value to merge
 */
export function mergeJsonDatasetValue(el, datasetKey, partialValue) {
  const currentValue = JSON.parse(el.dataset[datasetKey])
  setDatasetValue(
    el,
    datasetKey,
    JSON.stringify({
      ...currentValue,
      ...partialValue,
    }),
  )
}

/**
 * Set the data attribute of an element
 *
 * @param {DOMNode} el
 * @param {String} datasetKey Element dataset attribute (camelCased)
 * @param {String} value Value as an attribute-encoded string
 */
export function setDatasetValue(el, datasetKey, value) {
  el.dataset[datasetKey] = value
}
